<template>
  <v-card>
    <v-card-text>
      <h2 v-if="$route.name === 'Alert'" class="accent--text mb-4">
        Historique des statuts et motifs
      </h2>

      <v-row v-if="commentsLoader" align-content="center" justify="center">
        <v-col cols="12">
          <v-progress-linear
            color="accent"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col class="subtitle-1 text-center" cols="12"
          >Chargement des commentaires...</v-col
        >
      </v-row>

      <v-timeline dense clipped>
        <v-slide-x-transition group>
          <v-timeline-item
            v-for="comment in comments"
            :key="comment.id"
            class="mb-4"
            :color="comment.user.email == userEmail ? 'primary' : 'grey'"
            small
          >
            <v-row justify="space-between" align="center">
              <v-col cols="12">
                <span class="text-uppercase font-weight-bold">
                  {{ comment.user.firstname }}
                  {{ comment.user.lastname }}
                </span>
                <span> le {{ comment.updated_at | formatDateHour }} </span>
                <div v-if="comment.message">{{ comment.message }}</div>

                <!-- RELEVANT_STATUS -->
                <div v-if="comment.relevant_status">
                  <span class="font-weight-light"
                    >Statut de l'alerte changé de
                    <span class="font-weight-bold">{{
                      $t(`status.${comment.relevant_status.split(":")[0]}`)
                    }}</span>
                    à
                    <span class="font-weight-bold">{{
                      $t(`status.${comment.relevant_status.split(":")[1]}`)
                    }}</span>
                  </span>
                </div>

                <!-- RESULT_STATUS -->
                <div v-if="comment.result_status">
                  <span class="font-weight-light"
                    >Résultat d'analyse de l'alerte changé de
                    <span class="font-weight-bold">{{
                      $t(`result.${comment.result_status.split(":")[0]}`)
                    }}</span>
                    à
                    <span class="font-weight-bold">{{
                      $t(`result.${comment.result_status.split(":")[1]}`)
                    }}</span>
                  </span>
                </div>
              </v-col>
              <!-- <v-col class="text-right" cols="5">
                <TimelineUpdate
                  v-if="
                    comment.user.email == userEmail &&
                    comment.auto_generated == false
                  "
                  :comment="comment"
                />
                <TimelineDelete
                  v-if="
                    comment.user.email == userEmail &&
                    comment.auto_generated == false
                  "
                  :comment="comment"
                  :ruleId="ruleId"
                />
              </v-col> -->
            </v-row>
          </v-timeline-item>
        </v-slide-x-transition>

        <v-row
          v-if="comments.length == 0 && !commentsLoader"
          class="text-center"
        >
          <v-col>
            <span class="body-1"
              >Pas encore de commentaire(s) pour cette alerte.</span
            >
          </v-col>
        </v-row>

        <!-- <TimelineInput :alertId="alertId" :ruleId="ruleId" /> -->
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    alertId: {
      type: String,
      default: null
    },
    ruleId: {
      type: String,
      default: null
    }
  },

  components: {
    // TimelineInput: () => import("./TimelineInput"),
    // TimelineUpdate: () => import("./TimelineUpdate"),
    // TimelineDelete: () => import("./TimelineDelete")
  },

  computed: {
    ...mapState({
      commentsLoader: state => state.alerts.commentsLoader,
      comments: state => state.alerts.alertComments,
      userEmail: state => state.account.user.email
    })
  },

  mounted() {
    if (this.alertId) {
      this.$store.dispatch("alerts/fetchAlertComments", this.alertId);
    } else {
      this.$store.dispatch("alerts/fetchAlertComments", this.$route.params.id);
    }
  }
};
</script>
